import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-filter-drop-down",
  templateUrl: "./ag-filter-drop-down.component.html",
  styleUrls: ["./ag-filter-drop-down.component.scss"],
})
export class AgFilterDropDownComponent implements OnInit {
  @Input() label: string = ""
  @Input() style: filterStyle = "singleOutput"
  @Input() initlist: LabelValue[] = []
  @Input() spandMin: number
  @Input() spandMax: number

  @Output() changedValue = new EventEmitter<LabelValue[]>()
  @Output() changedSpand = new EventEmitter<number[]>()

  @HostListener("document:click", ["$event", "$event.target"])
  public onClick(): void {
    if (this.filtersShown) {
      if (this.insideClick === false) {
        if (this.applyActive) this.applyInput()
        else this.filtersShown = false
      } else {
        this.insideClick = false
      }
    }
  }

  filtersShown: boolean = false
  clearActive: boolean = false
  applyActive: boolean = false
  insideClick: boolean = false
  filtersActive: number = 0
  isMinFocused: boolean = false
  isMaxFocused: boolean = false
  initNumber: number
  list: LabelValue[] = []
  firstInit: number = 0

  constructor(public iconService: IconImageService) {}

  ngOnInit(): void {
    this.list = JSON.parse(JSON.stringify(this.initlist))
    this.list.forEach((element) => {
      if (element.value) this.filtersActive++
    })
    this.clearActive = this.filtersActive > 0 ? true : false
  }

  openDropDown() {
    this.filtersShown = !this.filtersShown
    if (this.applyActive) this.applyInput()
  }

  selectSingle(item: LabelValue) {
    this.applyActive = true
    this.clearActive = true
    item.value = !item.value
    if (item.value)
      this.list.forEach((element) => {
        if (element.label !== item.label) element.value = false
      })
  }
  selectMultiple(item: LabelValue) {
    this.applyActive = true
    this.clearActive = true
    item.value = !item.value
  }

  spandInputMin(event: any) {
    const value = Number((event.target as HTMLInputElement).value)

    this.spandMin = value

    if (!!this.spandMin) {
      this.applyActive = true
      this.clearActive = true
    }
  }

  spandInputMax(event: any) {
    const value = Number((event.target as HTMLInputElement).value)
    this.spandMax = value

    if (!!this.spandMax) {
      this.applyActive = true
      this.clearActive = true
    }
  }

  public onFocus(input: string) {
    if (input === "min") this.isMinFocused = true
    else this.isMaxFocused = true
  }

  public onBlur(input: string) {
    if (input === "min") this.isMinFocused = false
    else this.isMaxFocused = false
  }

  clearInput() {
    this.list.forEach((element) => {
      element.value = false
    })
    this.spandMax = -1
    this.spandMin = -1
    this.applyInput()
    this.filtersShown = true
  }

  applyInput() {
    if (this.style === "spandOutput") {
      if (this.spandMin > this.spandMax) this.spandMax = this.spandMin
      this.changedSpand.emit([this.spandMin, this.spandMax])
      this.spandMax = this.spandMax > -1 ? this.spandMax : undefined
      this.spandMin = this.spandMin > -1 ? this.spandMin : undefined
      this.applyActive = false
      this.clearActive = true
      this.filtersShown = false
      this.filtersActive = 0
      if (!!this.spandMin) this.filtersActive++
      if (!!this.spandMax) this.filtersActive++
      this.list = JSON.parse(JSON.stringify(this.list))
    } else {
      var returnList = this.list
      this.applyActive = false
      this.clearActive = true
      this.filtersShown = false
      this.filtersActive = 0
      this.list.forEach((element) => {
        if (element.value) this.filtersActive++
      })
      this.list = JSON.parse(JSON.stringify(this.list))
      this.changedValue.emit(returnList)
    }
  }
}
export interface LabelValue {
  label: string
  value: boolean
  img?: string
  imgFilter?: string
}
export type filterStyle = "singleOutput" | "multipleOutput" | "spandOutput"
