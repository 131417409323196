export const Environment = {
  AngularProduction: true,
  WebSoketURL: "", //ws://test.aeroguest.io:39285
  ApexxOrganizationId: "3c3cf164db0940f58dbf9db56c2f3631",
  ApexxUrl: "https://sandmgw.apexxfintech.com/mgw/getencryptedpayload",
  SpreedlyKey: "EVXzbUPz5ProeHiiCIrT3Rq9vIB",
  WebsocketGateUser: "", //ws://test.aeroguest.io:39286
  WebsocketGateAdmin: "", //ws://test.aeroguest.io:39285
  WebsocketGateCommunication: "", //ws://test.aeroguest.io:39245/chatHub
}
